// Generated by Framer (9a49031)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mGt9qIgJG"];

const serializationHash = "framer-PwmNc"

const variantClassNames = {mGt9qIgJG: "framer-v-cgtu0z"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const getProps = ({height, id, name, width, ...props}) => { return {...props, tujKXfOPW: name ?? props.tujKXfOPW ?? "Fintech"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;name?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, tujKXfOPW, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "mGt9qIgJG", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-PwmNc", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-cgtu0z", className)} data-border data-framer-name={"Fintech"} layoutDependency={layoutDependency} layoutId={"mGt9qIgJG"} ref={ref ?? ref1} style={{"--border-bottom-width": "2px", "--border-color": "rgba(142, 132, 255, 0.5)", "--border-left-width": "2px", "--border-right-width": "2px", "--border-style": "solid", "--border-top-width": "2px", borderBottomLeftRadius: 8, borderBottomRightRadius: 8, borderTopLeftRadius: 8, borderTopRightRadius: 8, ...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SW5zdHJ1bWVudCBTYW5zLTUwMA==", "--framer-font-family": "\"Instrument Sans\", \"Instrument Sans Placeholder\", sans-serif", "--framer-font-weight": "500", "--framer-letter-spacing": "0px", "--framer-line-height": "120%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-08633ce2-794d-47e8-9f1d-c93f863e9c58, rgb(62, 54, 166)))"}}>Fintech</motion.p></React.Fragment>} className={"framer-1ecvmx6"} data-framer-name={"Almost before we kne"} fonts={["GF;Instrument Sans-500"]} layoutDependency={layoutDependency} layoutId={"QdSW0Kija"} style={{"--extracted-r6o4lv": "var(--token-08633ce2-794d-47e8-9f1d-c93f863e9c58, rgb(62, 54, 166))", "--framer-paragraph-spacing": "0px"}} text={tujKXfOPW} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-PwmNc [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-PwmNc .framer-15fq6s3 { display: block; }", ".framer-PwmNc .framer-cgtu0z { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: flex-start; overflow: visible; padding: 6px 12px 6px 12px; position: relative; width: min-content; }", ".framer-PwmNc .framer-1ecvmx6 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-PwmNc .framer-cgtu0z { gap: 0px; } .framer-PwmNc .framer-cgtu0z > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-PwmNc .framer-cgtu0z > :first-child { margin-left: 0px; } .framer-PwmNc .framer-cgtu0z > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 31
 * @framerIntrinsicWidth 80
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]}}}
 * @framerVariables {"tujKXfOPW":"name"}
 * @framerImmutableVariables true
 */
const FramerLIrq9pN9g: React.ComponentType<Props> = withCSS(Component, css, "framer-PwmNc") as typeof Component;
export default FramerLIrq9pN9g;

FramerLIrq9pN9g.displayName = "category";

FramerLIrq9pN9g.defaultProps = {height: 31, width: 80};

addPropertyControls(FramerLIrq9pN9g, {tujKXfOPW: {defaultValue: "Fintech", displayTextArea: false, title: "Name", type: ControlType.String}} as any)

addFonts(FramerLIrq9pN9g, [{family: "Instrument Sans", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/LIrq9pN9g:default", url: "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npST3-QfwmS0v3_7Y.woff2"}, style: "normal", url: "https://fonts.gstatic.com/s/instrumentsans/v1/pximypc9vsFDm051Uf6KVwgkfoSxQ0GsQv8ToedPibnr-yp2JGEJOH9npST3-QfwmS0v3_7Y.woff2", weight: "500"}])